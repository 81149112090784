//区分生产与研发公共前缀api
const currentOrigin = window.location.origin;
let prefixUrl = "";
let productUrl = [
  "http://yshgjyd.nbhq.gov.cn",
  "https://yshgjyd.nbhq.gov.cn",
  "http://122.247.77.44",
  "https://122.247.77.44",
];
if (process.env.NODE_ENV === "production") {
  if (productUrl.includes(currentOrigin)) {
    prefixUrl = "/yz/jieneng";
  } else {
    prefixUrl = "";
  }
} else {
  prefixUrl = "";
}
export default prefixUrl;
